import React, { useContext, useState } from "react";

const initialFormContextState = {
  form: {
    email_address: "",
    first_name: "",
    last_name: "",
    date_of_birth: "",
    language: "",
    country: "",
    bucketlist_dest1: "",
    bucketlist_dest2: "",
    bucketlist_dest3: "",
    activities: "",
  },
  setForm: () => {
    throw new Error("You forgot to wrap this in a FormContextProvider object");
  },
};

type FormType = {
  email_address: string; // "developer@dentsuachtung.com",
  first_name: string; // "Dev",
  last_name: string; // "Achtung",
  date_of_birth: string; // "12-13-1900", // Note, birthday is in US format: Month, Day, Year
  language: string; // "en",
  country: string; // "nl",
  bucketlist_dest1: string; // "AMS",
  bucketlist_dest2: string; // "MAD",
  bucketlist_dest3: string; // "NYC",
  activities: string; // "CUL|ART|NAT|WOC"

  day: string; // Not in final form - only for /personal-details page
  month: string; // Not in final form - only for /personal-details page
  year: string; // Not in final form - only for /personal-details page
};

interface ContextShape {
  form: FormType;
  setForm: React.Dispatch<React.SetStateAction<FormType>>;
}

const FormContext = React.createContext<ContextShape>(initialFormContextState);

export const FormContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [form, setForm] = useState<FormType>(initialFormContextState.form);

  return (
    <FormContext.Provider
      value={{
        form,
        setForm,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export const useFormContext = (): ContextShape => useContext(FormContext);
