import React from "react";

import { LangContextProvider } from "./src/context/langContext";
import { FormContextProvider } from "./src/context/formContext";
import { ErrorsContextProvider } from "./src/context/errorsContext";
import { CitiesContextProvider } from "./src/context/citiesContext";
import { CountryContextProvider } from "./src/context/countryContext";
import { IntroContextProvider } from "./src/context/introContext";

export const wrapRootElement = ({ element }) => (
  <CountryContextProvider>
    <LangContextProvider>
      <FormContextProvider>
        <ErrorsContextProvider>
          <IntroContextProvider>
            <CitiesContextProvider>{element}</CitiesContextProvider>
          </IntroContextProvider>
        </ErrorsContextProvider>
      </FormContextProvider>
    </LangContextProvider>
  </CountryContextProvider>
);
