import React, { useContext, useState } from "react";

const initialErrorsContextState = {
  errors: 0,
  setErrors: () => {
    throw new Error(
      "You forgot to wrap this in a ErrorsContextProvider object"
    );
  },
};

interface ContextShape {
  errors: number;
  setErrors: React.Dispatch<React.SetStateAction<number>>;
}

const ErrorsContext = React.createContext<ContextShape>(
  initialErrorsContextState
);

export const ErrorsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [errors, setErrors] = useState(initialErrorsContextState.errors);

  return (
    <ErrorsContext.Provider
      value={{
        errors,
        setErrors,
      }}
    >
      {children}
    </ErrorsContext.Provider>
  );
};

export const useErrorsContext = (): ContextShape => useContext(ErrorsContext);
