import React, { useContext, useEffect, useState } from "react";

const initialCountryContextState = {
  country: "nl" as Country,
  setCountry: () => {
    throw new Error(
      "You forgot to wrap this in a CountryContextProvider object"
    );
  },
  countryReady: false,
};

type Country = "nl" | "be" | "lu";

interface ContextShape {
  country: Country;
  setCountry: React.Dispatch<React.SetStateAction<Country>>;
  countryReady: boolean;
}

const CountryContext = React.createContext<ContextShape>(
  initialCountryContextState
);

export const CountryContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [country, setCountry] = useState<Country>(
    initialCountryContextState.country
  );
  const [countryReady, setCountryReady] = useState(
    initialCountryContextState.countryReady
  );

  useEffect(() => {
    fetch("https://api.country.is/")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error, status = ${response.status}`);
        }
        return response.json();
      })
      .then((json) => {
        setCountry(json.country);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setCountryReady(true));
  }, []);

  return (
    <CountryContext.Provider
      value={{
        country,
        setCountry,
        countryReady,
      }}
    >
      {children}
    </CountryContext.Provider>
  );
};

export const useCountryContext = (): ContextShape => useContext(CountryContext);
